import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import LayoutTYP from '../components/Containers/LayoutTYP';
import { useLayoutQueries } from '../utils/queries';

const AppWrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 100vh;
  #progress-bar {
    display: none;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  min-height: 100px;
  margin: 0 auto;
  padding: 50px 0;
  margin-top: 40px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  background: #fff;
  overflow: hidden;

  .link {
    text-decoration: none;
    color: #fff;
    background: #04bf5f;
    padding: 20px;
    font-size: 20px;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
  }
`;

const NotFoundPage = () => {
  const { config, backgroundImage, siteImages } = useLayoutQueries();
  return (
    <LayoutTYP
      config={config}
      backgroundImage={backgroundImage}
      siteImages={siteImages}
    >
      <AppWrapper>
        <ErrorContainer>
          <h1>404 - Page Not Found</h1>
          <Link to="/" className="link">
            Return to the survey
          </Link>
        </ErrorContainer>
      </AppWrapper>
    </LayoutTYP>
  );
};

export default NotFoundPage;
